import { Grid, Select, Text } from "@mantine/core";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

export default function DatePicker(props: any) {
  return (
    <Grid className="pt-0 pl-8">
      <Grid.Col span={12} className="pl-0">
        <Text fz="md" className="text-secondary">
          Date Range<sup style={{ color: "#ff0000" }}>*</sup>
        </Text>
      </Grid.Col>
      <Grid.Col
        span={11}
        className="pt-0 pl-0"
        style={{ position: "relative" }}
      >
        <Select
          placeholder=" Please Select a Date"
          data={props.dateRange}
          icon={<CalendarIcon className="h-5 w-5 ml-4 mr-1" />}
          rightSection={
            <ChevronDownIcon className="h-4 w-4 mr-3 text-secondary" />
          }
          value={props.dates}
          onChange={props.handleDateChange}
        />
      </Grid.Col>
    </Grid>
  );
}
